/* eslint-disable camelcase */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './BookingConfirmed.scss';

import { SummaryCard } from '../../components/SummaryCard/SummaryCard';

import { ExtrasCard } from '../../components/SummaryCard/ExtrasCard/ExtrasCard';

import docIconDefault from '../../components/assets/icons/default/09/noun-policies-2774558-222222.svg';
import docIcon from '../../components/assets/document.svg';

import hotelIconDefault from '../../components/assets/icons/default/09/noun-hotel-3918347-222222.svg';
import hotelIcon from '../../components/assets/hotel.svg';

import planeDefault from '../../components/assets/icons/default/09/noun-plane-4216230-222222.svg';
import plane from '../../components/assets/plane.svg';

import plusDefault from '../../components/assets/icons/default/09/noun-plus-2178969-222222.svg';
import plus from '../../components/assets/plus.svg';

import { SuccessIcon } from '../../components/Header/SuccessIcon/SuccessIcon';

import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import { fbqPurchase } from '../../hooks/fb';

import { sessionGetSearchParams } from '../../services/search';
import { adultCountFromRooms, childCountFromRooms, childCountFromRoomsExInfants } from '../../services/pax';

import { purchasePackage, purchasePackageObject } from '../../services/ggle';
import { getCookie, cookie } from '../../services/session';

import { Session, Package, SearchSelectionRoom } from '../../hooks/types';

import {
  packageFromBooking,
  bookingMailDone,
  siteLog,
  bookingMailOut,
} from '../../hooks/useRequest';
import dayjs from 'dayjs';

// @ts-ignore
import gtag from 'ga-gtag'

interface PackageBookingConfirmedProps {
  bookingData: Session;
  searchedRooms: SearchSelectionRoom[];
}

const PackageBookingConfirmed: FunctionComponent<PackageBookingConfirmedProps> = ({
  bookingData,
  searchedRooms
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();
  const [bookingRequested, setBookingRequest] = useState<boolean>(false);

  const navigate = useNavigate();

  const [bookingState, setBookingState] = useState<any>(false);

  const [accNameLoc, setAcc] = useState<any>(false);

  const [packageData, setPak] = useState<Package>();

  const [pakAdditional, setPakAdd] = useState<any>(null);

  useEffect(() => {
    sessionStorage.removeItem('session_cr');
    if (!bookingRequested) {
 
      const getPackageData = async () => {
        const sessionId = searchParams.get('session');
        const bookingId = searchParams.get('bid');

        if (sessionId == null) {
          return navigate('/');
        }
        if (bookingId == null) {
          return navigate('/');
        }

        const nBId = Number(bookingId);
        const results = await packageFromBooking(userToken, sessionId, nBId);
        
        if (results?.package_from_booking?.result) {

          const pakAcc = results.package_from_booking.result.accommodation
          if (pakAcc != null) {
            const accName = pakAcc.name;
            const accLoc =
            pakAcc.resort.name +
              ', ' +
              pakAcc.resort.regions[0].name +
              ', ' +
              pakAcc.resort.regions[0].destinations[0].name;
            setAcc({ accName, accLoc });
          }

          const roomData =  results.package_from_booking.result.rooms;
          const extrasState: any = [];

          let adultCount = 0;
          let childCount = 0;
          for(const x of roomData){
            adultCount += x.adults;
            childCount += x.children;
          }

          const paxCount = adultCount + childCount;

          extrasState.push({title: '22kg Bag Allowance', quanity: results.package_from_booking.result.flight.bag_count});
          extrasState.push({title: '10kg Hand Baggage', quanity: results.package_from_booking.result.flight.bag_count});
          extrasState.push({title: 'Coach Transfers', quanity: -1});
          extrasState.push({title: 'ATOL Protection', quanity: -1});

          setPakAdd(extrasState)
          setPak(results.package_from_booking.result);

          const passengers = bookingData.basket?.people
          ?.map((passenger: any) => {
            return `${passenger.first_name} ${passenger.second_name}`;
          })
          .join('\n');

          let bookingRef = null;
          if (bookingData.basket != null) {
            bookingRef = bookingData.basket.booking?.reference;
            if (bookingRef == '') {
              bookingRef = bookingData.basket.booking?.id;
            }
          }
      

          setBookingState({
            reference: bookingRef,
            bookingDate: dayjs(bookingData.basket?.booking?.date_of_booking).format(
              'DD/MM/YYYY',
            ),
            passengerNames: passengers,
          });

          if(searchedRooms != null && pakAcc != null){
            const cin = dayjs(results.package_from_booking.result.flight.outbound_arrival).format('YYYY-MM-DD');
            const cout = dayjs(results.package_from_booking.result.flight.inbound_departure).format('YYYY-MM-DD');
            const adults = adultCountFromRooms(searchedRooms);
            const children = childCountFromRooms(searchedRooms);
            const childrenExInf = childCountFromRoomsExInfants(searchedRooms);

            fbqPurchase(pakAcc.resort.name, pakAcc.resort.regions[0].name, pakAcc.resort.regions[0].destinations[0].name, cin, cout, pakAcc.id, adults , children, 
              bookingData.basket.price);
          }

          if (bookingData.basket.booking != null){

            await bookingMailOut(userToken, sessionId, bookingData.basket.booking.id);

            if(bookingData.basket.booking.confirmed == 1){

              let doGoogle = false;
              // const val = await bookingMailDone(userToken, bookingData.basket.booking.id) as any;
              // if(val == null){
              //   doGoogle = false;
              // }
              // if(val.booking_maildone.result != null){
              //   if(val.booking_maildone.result == 1){
              //     doGoogle = false;
              //   }
              // }
              const ggle = getCookie('ggl' + bookingId, false);
              if(ggle == null){
                doGoogle = true;
              }
              if(doGoogle){
                const po = purchasePackageObject(bookingData.basket, results.package_from_booking.result);
                const strPo = JSON.stringify(po);  
                siteLog(userToken, 'GA4', strPo, '',-1, -1, 0, sessionId, 'sending ga4 package');
                purchasePackage(bookingData.basket, results.package_from_booking.result);
                cookie('ggl' + bookingId, 1, false, 5);
              }
            }
          }

          setBookingRequest(true);

        }
      }
      getPackageData();
    }
  }, []);


  if(packageData == null){
    return null;
  }

let docIco = docIconDefault;
let hotelIco = hotelIconDefault;
let flightIco = planeDefault;
let plusIco = plusDefault;
if(configuration.icon_set == 'blue'){
  docIco = docIcon;
  hotelIco = hotelIcon;
  flightIco = plane;
  plusIco = plus;
}

  let extraSec = null;
  if(pakAdditional != null){
    extraSec = (
      <div className='booking-summary-list--item'>
        <ExtrasCard title={'Included'} icon={plusIco} packExtras={pakAdditional} />
      </div>
    );
  }



  const rData = [];
  for (const r of packageData?.rooms) {
    let strAdlt = r.adults + ' adults';
    if (r.adults == 1) {
      strAdlt = r.adults + ' adult';
    }
    let strChild = '';
    if (r.children > 0) {
      strChild = ' ' + r.children + ' children';
      if (r.children == 1) {
        strChild = ' ' + r.children + ' child';
      }
    }

    rData.push(
      <div className='summary-card-list--item'>
        <span>
          Room {r.room_number} ({strAdlt}
          {strChild})
        </span>
        <span className='summary-card-value'>
          {r.description} ({r.board_description})
        </span>
      </div>,
    );
  }
  let accSec = null;
  accSec = (
    <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={hotelIco} alt='Section icon' />
        <span className={'card-title'}>Accommodation</span>
      </div>

      <div className='summary-card-wrapper--body'>
        <div className='summary-card-list'>
          <div className='summary-card-list--item'>
            <span>Name</span>
            <span>{accNameLoc.accName}</span>
          </div>
          <div className='summary-card-list--item'>
            <span>Destination</span>
            <span>{accNameLoc.accLoc}</span>
          </div>

          {rData}
        </div>
      </div>
    </div>
  );

  const outboundAirline = packageData.flight.airline + ' ' + packageData.flight.outbound_number;
  const inboundAirline = packageData.flight.airline + ' ' + packageData.flight.inbound_number;
  
  
  const outboundDeparting = dayjs(packageData.flight.outbound_departure).format('HH:mm') + ' ' + packageData.flight.departure_point.name;
  const outboundArrival =  dayjs(packageData.flight.outbound_arrival).format('HH:mm') + ' ' + packageData.flight.arrival_airport.name;

  const inboundDeparting =  dayjs(packageData.flight.inbound_departure).format('HH:mm') + ' ' + packageData.flight.arrival_airport.name;
  const inboundArrival =  dayjs(packageData.flight.inbound_arrival).format('HH:mm') + ' ' + packageData.flight.departure_point.name;

  let flightSec = (
    <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={flightIco} alt='Section icon' />
        <span className={'card-title'}>Flights</span>
      </div>

      <div className='summary-card-wrapper--body'>
        <div className='summary-card-list'>
          <div className='summary-card-list--item'>
            <span>Outbound</span>
            <span>{dayjs(packageData.flight.outbound_departure).format('ddd DD MMM YYYY')}</span>
          </div>

          <div className='summary-card-list--item'>
            <span className={'light-title'}>{outboundAirline}</span>
            <span>{outboundDeparting}</span>
          </div>

          <div className=' separator'>
             <span> </span>
             <span>-</span>
          </div>

          <div className='summary-card-list--item'>
            <span className={'light-title'}></span>
            <span>{outboundArrival}</span>
          </div>
          
          
          <div className='summary-card-list--item'>
            <span>Inbound</span>
            <span>{dayjs(packageData.flight.inbound_departure).format('ddd DD MMM YYYY')}</span>
          </div>
          {/* {generateDateRanges(flightsData.inboundDateRange, flightsData.inboundAirline)} */}
        
          <div className='summary-card-list--item'>
            <span className={'light-title'}>{inboundAirline}</span>
            <span>{inboundDeparting}</span>
          </div>

          <div className=' separator'>
             <span> </span>
             <span>-</span>
          </div>

        
          <div className='summary-card-list--item'>
            <span className={'light-title'}></span>
            <span>{inboundArrival}</span>
          </div>


        </div>
      </div>
    </div>
  );;






  return (
    <div className='booking-confirmed-page-wrapper--body'>
      <div className='booking-summary-list'>
        {bookingState && (
          <div className='booking-summary-list--item'>
            <SummaryCard title={'Booking Summary'} icon={docIco} data={bookingState} />
          </div>
        )}
        {<div className='booking-summary-list--item'>{accSec}</div>}

        <div className='booking-summary-list--item'>
        {flightSec}
        </div>

        <div className='booking-summary-list--item'>
        {extraSec}
        </div>
  
  
      </div>
    </div>
  );
};

export default PackageBookingConfirmed;
